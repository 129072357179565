import React from 'react'
import "./Allyouneedtoknow.css"
import { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Illustration from './img/hypescore-illustration.png'

function Allyouneedtoknow() {

    
        const [number, setNumber] = useState(150)
        const url = 'https://mintscore.herokuapp.com/upcoming'
    
        useEffect(() => {
    
          
            const fetchData = async () => {
              
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    setNumber(result.data.length)
                
                    
                  })
              }
              
    
              fetchData();
        }, [])


    return (
        <div className='flexbox'>
                


                <div className="Allyouneedtoknow-container">
                    <div className="title-container">
                    <p className="big-title">Find <span className="nft-title">NFTs</span> that will succeed with the power of AI</p>
                    </div>
                    <div className="title-container">
                    <p className="subtitle">Powerful Social Media analysis tool of upcoming & ongoing NFTs collections to help you make the right choice.</p>
                    </div>
                    <div className="button-container-header">
                        <div>
                        <button className='submitcollection-button'><Link className="footer-links" to="/submit">Submit Collection</Link></button>
                        </div>
                        <div>
                        <button className='upcoming-button'><Link className="footer-links" to="/upcoming">Upcoming Collections</Link></button>
                        </div>
                    </div>
                    <div className="title-container">
                    <p className="numberof-collections">There are currently {number} collections listed.</p>
                    </div>
                </div>

                <div className='image-container-ayntk'>
                    <img className='illustration' src={Illustration}/>
                </div>
        </div>
    )
}

export default Allyouneedtoknow
